import React from 'react';
import { Query } from 'react-apollo';

import { Header, Routes, UserContext, SubscriptionWithNotifications, Loader, ErrorBoundary } from './index';
import { ME } from '../gql';
import { redirectToSsoAuthWithCbLink, redirectToSso } from '../helpers';

import Navbar from './Navbar/Navbar';

class MainContainer extends React.PureComponent {
  render() {
    return (
      <Query query={ME}>
        {({
          client, loading, error, data,
        }) => {
          if (loading) return <Loader />;
          if (error) {
            const { message } = error;
            if (message.includes('NO_ROLE')) {
              redirectToSso();
            } else if (message.includes('FORBIDDEN_DISTRICT')) {
              this.props.history.push('/forbidden');
            } else {
              redirectToSsoAuthWithCbLink();
            }
            return null;
          }

          const user = data.me;
          const isRole = roles => user.role && roles.includes(user.role.name);

          return (
            <ErrorBoundary>
              <UserContext.Provider value={{ user, client, isRole }}>
                <Header location={this.props.location} />
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
                <SubscriptionWithNotifications history={this.props.history} user={user} isRole={isRole} />
                <Navbar />
              </UserContext.Provider>
            </ErrorBoundary>
          );
        }}
      </Query>
    );
  }
}
export default MainContainer;
